/* Existing CSS */

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Updated property */
  background-image: url("../images/homebackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 140px;
  color: white;
  font-weight: 800;
  transition: 0.5s;
}

.content h1:hover {
  -webkit-text-stroke: 2px white;
  color: transparent;
}

:root {
  --color-primary: #f6aca2;
  --color-secondary: #f49b90;
  --color-tertiary: #f28b7d;
  --color-quaternary: #f07a6a;
  --color-quinary: #ee6352;
}

.centeredHeading {
  text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary),
    9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  font-family: bungee, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2rem + 5vw);
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  animation: shadows 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

@keyframes shadows {
  /* Animation keyframes code */
}

.iconContainer {
  position: absolute;
  z-index: 1;
  margin-top: 200px;
}

.iconRow {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.icon {
  font-size: 24px;
  margin: 0 10px;
  color: #f6aca2;
}

.iconContainer a:hover {
  color: yellow;
  transform: scale(2);
}

/* Fade-in animation for popup */
@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    transform: translate(-3px, -3px);
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  20% {
    transform: translate(-6px, -6px);
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  30% {
    transform: translate(-9px, -9px);
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  40% {
    transform: translate(-12px, -12px);
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  50% {
    transform: translate(-12px, -12px);
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  60% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  80% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  100% {
    text-shadow: none;
  }
}
