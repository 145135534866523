.container {
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/projectbackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contributionContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

}

@media (max-width: 1100px) {
  .contributionContainer {
    display: none; /* Hide the contribution container on smaller screens */
  }
}



.gridContainer {
  margin: 20px 20px px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 1100px) {
  .gridContainer {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Keep the original grid template for larger screens */
  }

  

}

.gridItem {
  padding: 15px;
  background-color: #f5ecec;
  border-radius: 15px;
  border: solid 3px rgb(5, 3, 3);
  text-align: center;
  text-decoration: none;
  color: #0c0b0b;
  padding-bottom: 20px;
}

.gridItem:hover {
  background-color: #ecb90e;
}

.icon {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.buttonContainer {

  padding-bottom: 20px; /* Add padding to the bottom */
  display: flex;
  justify-content: center;

  margin-bottom: 20px; /* Add margin to the bottom */
}

.projectbutton {
  padding: 10px 20px;
  background-color: #ecb90e;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid black;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  animation: pulse 2s infinite;
}

.projectbutton a {
  color: black;
  text-decoration: none;
  border-bottom: none;
}

@keyframes pulse {
  0% {
    transform: scale(1.3);
    box-shadow: 0 0 0 0 rgba(220, 235, 9, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(46, 204, 113, 0);
  }
  100% {
    transform: scale(1.3);
    box-shadow: 0 0 0 0 rgba(46, 204, 113, 0);
  }
}



.animation{
  display: flex;
  justify-content: center;
  align-items: center;
 
  
 
}