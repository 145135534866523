.about {
  background-image: url("../images/aboutbackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  padding: 0;
  width: 100%;
}

.aboutdetails{
  padding-top:120px;
}

.imagecontainer{
  padding-top: 30px;
  padding-bottom: 30px;
}

.image{
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #F8CA61;
}

.image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about h1,
.about h2,
.about h3,
.about p {
  color: black;
}



.about .icons1{
  padding-bottom: 20px;
}
.about .icons2{
  padding-bottom: 80px;
}

.about .icons1 img {
  height: 40px;
  margin: 0.5rem; /* Add margin to create space between icons */
}

.about .icons2 img {
  height: 40px;
  margin: 0.5rem; /* Add margin to create space between icons */
}




@media screen and (max-width: 500px) {
  .about .container {
    grid-template-columns: 1fr;
  }

  .about .container {
    width: 100%;
  }

  .about .container img {
    padding: 1rem;
    width: 90vw;
    padding-left: 25px
  }

  .about .container .col-2 {
    padding: 0;
  }

  .about button {
    margin-bottom: 1rem;
  }
}
